import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    vendorList: [
      {
        id: 1,
        entity: ["AB", "AA"],
        status: "registered",
        review: "Black List",
        vendor_name: "Apple",
        company_type: "PT",
        pic: "Ahmad",
        email: "john.doe@example.com",
        contact_number: 81234567890,
        cat_level_3: ["Outsourcing", "HR System"],
        specialist: "Daily Worker",
        city_basetown: "Jakarta",
        type_of_vendor: "Mancfacture",
        top: "14 Hari",
        project_name: "Outsourcing",
        due_diligence_date: "Apr 27, 2022",
        due_diligence_expired_date: "Apr 27, 2023",
        remarks: "Passed",
      },
      {
        id: 2,
        entity: ["AA", "AC"],
        status: "registered",
        review: "Good",
        vendor_name: "Sharp",
        company_type: "CV",
        pic: "Dian",
        email: "janedoe5678@yahoo.com",
        contact_number: 84567890123,
        cat_level_3: ["Outsourcing", "HR System"],
        specialist: "Daily Worker",
        city_basetown: "Jakarta",
        type_of_vendor: "Services",
        top: "14 Hari",
        project_name: "Outsourcing",
        due_diligence_date: "Apr 27, 2022",
        due_diligence_expired_date: "Apr 27, 2023",
        remarks: "Passed",
      },
      {
        id: 3,
        entity: ["AB", "AD", "AC"],
        status: "registered",
        review: "Good",
        vendor_name: "Cosmos",
        company_type: "PT",
        pic: "Gita",
        email: "john.doe123@hotmail.com",
        contact_number: 87890123456,
        cat_level_3: ["Outsourcing", "HR System"],
        specialist: "Daily Worker",
        city_basetown: "Bandung",
        type_of_vendor: "Mancfacture",
        top: "30 Hari",
        project_name: "Outsourcing",
        due_diligence_date: "Apr 27, 2022",
        due_diligence_expired_date: "Apr 27, 2023",
        remarks: "Passed",
      },
      {
        id: 4,
        entity: ["AA", "AB"],
        status: "registered",
        review: "Black List",
        vendor_name: "LG",
        company_type: "CV",
        pic: "Kiki",
        email: "john.doe456@example.net",
        contact_number: 91234567890,
        cat_level_3: ["Outsourcing", "Security System"],
        specialist: "Security",
        city_basetown: "Semarang",
        type_of_vendor: "Distributor",
        top: "30 Hari",
        project_name: "Outsourcing",
        due_diligence_date: "Apr 27, 2022",
        due_diligence_expired_date: "Apr 27, 2023",
        remarks: "Passed",
      },
      {
        id: 5,
        entity: ["AC"],
        status: "registered",
        review: "Good",
        vendor_name: "Samsung",
        company_type: "PT",
        pic: "Rudi",
        email: "johndoe789@gmail.com",
        contact_number: 92345678901,
        cat_level_3: ["Outsourcing", "Security System"],
        specialist: "Security",
        city_basetown: "Surabaya",
        type_of_vendor: "Services",
        top: "30 Hari",
        project_name: "Outsourcing",
        due_diligence_date: "Apr 27, 2022",
        due_diligence_expired_date: "Apr 27, 2023",
        remarks: "Passed",
      },
      {
        id: 6,
        entity: ["AB", "AD"],
        status: "registered",
        review: "Good",
        vendor_name: "Asus",
        company_type: "PT",
        pic: "Rina",
        email: "johndoe456@example.net",
        contact_number: 97890123456,
        cat_level_3: ["Outsourcing", "HR System"],
        specialist: "Cleaning Service",
        city_basetown: "Surabaya",
        type_of_vendor: "General trader",
        top: "60 Hari",
        project_name: "Outsourcing",
        due_diligence_date: "Apr 27, 2022",
        due_diligence_expired_date: "Apr 27, 2023",
        remarks: "Passed",
      },
      {
        id: 7,
        entity: ["AA"],
        status: "registered",
        review: "Good",
        vendor_name: "Dominos",
        company_type: "CV",
        pic: "Candra",
        email: "johndoe1234@gmail.com",
        contact_number: 83456789012,
        cat_level_3: ["Event", "Percetakan"],
        specialist: "Sablon",
        city_basetown: "Jakarta",
        type_of_vendor: "General trader",
        top: "14 Hari",
        project_name: "Advertising",
        due_diligence_date: "May 17, 2022",
        due_diligence_expired_date: "May 17, 2023",
        remarks: "Passed",
      },
      {
        id: 8,
        entity: ["AC", "AB"],
        status: "registered",
        review: "Good",
        vendor_name: "Cube Gaming",
        company_type: "PT",
        pic: "Fajar",
        email: "jane.doe@example.org",
        contact_number: 86789012345,
        cat_level_3: ["Event", "Percetakan"],
        specialist: "Sablon",
        city_basetown: "Bandung",
        type_of_vendor: "Mancfacture",
        top: "14 Hari",
        project_name: "Advertising",
        due_diligence_date: "May 17, 2022",
        due_diligence_expired_date: "May 17, 2023",
        remarks: "Passed",
      },
      {
        id: 9,
        entity: ["AD"],
        status: "registered",
        review: "Black List",
        vendor_name: "Yamaha",
        company_type: "PT",
        pic: "Joko",
        email: "jane.doe123@example.com",
        contact_number: 90123456789,
        cat_level_3: ["Event", "Percetakan"],
        specialist: "Sablon",
        city_basetown: "Bandung",
        type_of_vendor: "Distributor",
        top: "30 Hari",
        project_name: "Advertising",
        due_diligence_date: "May 17, 2022",
        due_diligence_expired_date: "May 17, 2023",
        remarks: "Passed",
      },
      {
        id: 10,
        entity: ["AC"],
        status: "registered",
        review: "Good",
        vendor_name: "Panasonic",
        company_type: "CV",
        pic: "Lina",
        email: "janedoe789@example.org",
        contact_number: 92345678901,
        cat_level_3: ["Event", "Percetakan"],
        specialist: "Sablon",
        city_basetown: "Semarang",
        type_of_vendor: "Distributor",
        top: "30 Hari",
        project_name: "Advertising",
        due_diligence_date: "May 17, 2022",
        due_diligence_expired_date: "May 17, 2023",
        remarks: "Not Passed",
      },
      {
        id: 11,
        entity: ["AB", "AD", "AA"],
        status: "registered",
        review: "Good",
        vendor_name: "Lion Star",
        company_type: "CV",
        pic: "Mira",
        email: "johndoe123@hotmail.com",
        contact_number: 93456789012,
        cat_level_3: ["Event", "Printing"],
        specialist: "Baner",
        city_basetown: "Semarang",
        type_of_vendor: "Services",
        top: "30 Hari",
        project_name: "Advertising",
        due_diligence_date: "May 17, 2022",
        due_diligence_expired_date: "May 17, 2023",
        remarks: "Passed",
      },
      {
        id: 12,
        entity: [],
        status: "not-registered",
        review: "Good",
        vendor_name: "Nokia",
        company_type: "CV",
        pic: "Putri",
        email: "janedoe123@example.com",
        contact_number: 96789012345,
        cat_level_3: ["Event", "Printing"],
        specialist: "Baner",
        city_basetown: "Surabaya",
        type_of_vendor: "General trader",
        top: "60 Hari",
        project_name: "Advertising",
        due_diligence_date: "May 17, 2022",
        due_diligence_expired_date: "May 17, 2023",
        remarks: "Passed",
      },
      {
        id: 13,
        entity: [],
        status: "not-registered",
        review: "Good",
        vendor_name: "Pizza Hut",
        company_type: "CV",
        pic: "Budi",
        email: "jane.doe@example.com",
        contact_number: 82345678901,
        cat_level_3: ["Office Supply", "Catering"],
        specialist: "Meals",
        city_basetown: "Jakarta",
        type_of_vendor: "General trader",
        top: "14 Hari",
        project_name: "Consumable",
        due_diligence_date: "May 30, 2022",
        due_diligence_expired_date: "May 30, 2023",
        remarks: "Passed",
      },
      {
        id: 14,
        entity: [],
        status: "not-registered",
        review: "Good",
        vendor_name: "Samsung",
        company_type: "PT",
        pic: "Eka",
        email: "johndoe@example.net",
        contact_number: 85678901234,
        cat_level_3: ["Office Supply", "Catering"],
        specialist: "Snack",
        city_basetown: "Bandung",
        type_of_vendor: "Mancfacture",
        top: "14 Hari",
        project_name: "Consumable",
        due_diligence_date: "May 30, 2022",
        due_diligence_expired_date: "May 30, 2023",
        remarks: "Passed",
      },
      {
        id: 15,
        entity: ["AC", "AA"],
        status: "registered",
        review: "Good",
        vendor_name: "Xiomi",
        company_type: "PT",
        pic: "Hadi",
        email: "janedoe456@gmail.com",
        contact_number: 88901234567,
        cat_level_3: ["Office Supply", "Catering"],
        specialist: "Meals",
        city_basetown: "Bandung",
        type_of_vendor: "Mancfacture",
        top: "30 Hari",
        project_name: "Consumable",
        due_diligence_date: "May 30, 2022",
        due_diligence_expired_date: "May 30, 2023",
        remarks: "Passed",
      },
      {
        id: 16,
        entity: ["AA", "AD"],
        status: "registered",
        review: "Good",
        vendor_name: "Honda",
        company_type: "PT",
        pic: "Indra",
        email: "johndoe789@yahoo.com",
        contact_number: 89012345678,
        cat_level_3: ["Office Supply", "HSE"],
        specialist: "jaket",
        city_basetown: "Bandung",
        type_of_vendor: "Distributor",
        top: "30 Hari",
        project_name: "Consumable",
        due_diligence_date: "May 30, 2022",
        due_diligence_expired_date: "May 30, 2023",
        remarks: "Passed",
      },
      {
        id: 17,
        entity: [],
        status: "not-registered",
        review: "Good",
        vendor_name: "Logitec",
        company_type: "CV",
        pic: "Oki",
        email: "john.doe789@yahoo.com",
        contact_number: 95678901234,
        cat_level_3: ["Office Supply", "HSE"],
        specialist: "Sarung tangan",
        city_basetown: "Surabaya",
        type_of_vendor: "Services",
        top: "60 Hari",
        project_name: "Consumable",
        due_diligence_date: "May 30, 2022",
        due_diligence_expired_date: "May 30, 2023",
        remarks: "Passed",
      },
    ],
  },
  getters: {},
  mutations: {},
  actions: {},
  modules: {},
});
