<template>
  <div>
    <v-card>
      <v-card-title>
        <span class="headline">Vendor Details</span>
      </v-card-title>

      <v-container>
        <v-row>
          <v-col cols="6">
            <v-list>
              <v-list-item>
                <v-list-item-content>
                  <v-list-item-title>Vendor Name</v-list-item-title>
                  <v-list-item-subtitle>{{
                    detail.vendor_name
                  }}</v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>

              <v-list-item>
                <v-list-item-content>
                  <v-list-item-title>Entity</v-list-item-title>
                  <v-list-item-subtitle>
                    <v-chip
                      v-for="entity in detail.entity"
                      :key="entity"
                      label
                      small
                      style="margin: 10px"
                      >{{ entity }}</v-chip
                    >
                  </v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>

              <v-list-item>
                <v-list-item-content>
                  <v-list-item-title>Status</v-list-item-title>
                  <v-list-item-subtitle>{{
                    detail.status
                  }}</v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>

              <v-list-item>
                <v-list-item-content>
                  <v-list-item-title>Review</v-list-item-title>
                  <v-list-item-subtitle>{{
                    detail.review
                  }}</v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>

              <v-list-item>
                <v-list-item-content>
                  <v-list-item-title>PIC</v-list-item-title>
                  <v-list-item-subtitle>{{ detail.pic }}</v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>

              <v-list-item>
                <v-list-item-content>
                  <v-list-item-title>Email</v-list-item-title>
                  <v-list-item-subtitle>{{
                    detail.email
                  }}</v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>

              <v-list-item>
                <v-list-item-content>
                  <v-list-item-title>Contact Number</v-list-item-title>
                  <v-list-item-subtitle>{{
                    detail.contact_number
                  }}</v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>

              <!-- Add more fields as needed -->
            </v-list>
          </v-col>

          <v-col cols="6">
            <v-list>
              <v-list-item>
                <v-list-item-content>
                  <v-list-item-title>Commodity</v-list-item-title>
                  <v-list-item-subtitle>
                    <v-chip
                      v-for="category in detail.cat_level_3"
                      :key="category"
                      label
                      small
                      style="margin: 10px"
                      >{{ category }}</v-chip
                    >
                  </v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>

              <v-list-item>
                <v-list-item-content>
                  <v-list-item-title>City/Basetown</v-list-item-title>
                  <v-list-item-subtitle>{{
                    detail.city_basetown
                  }}</v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>

              <v-list-item>
                <v-list-item-content>
                  <v-list-item-title>Type of Vendor</v-list-item-title>
                  <v-list-item-subtitle>{{
                    detail.type_of_vendor
                  }}</v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>

              <v-list-item>
                <v-list-item-content>
                  <v-list-item-title>Top</v-list-item-title>
                  <v-list-item-subtitle>{{ detail.top }}</v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>

              <v-list-item>
                <v-list-item-content>
                  <v-list-item-title>Project Name</v-list-item-title>
                  <v-list-item-subtitle>{{
                    detail.project_name
                  }}</v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>

              <v-list-item>
                <v-list-item-content>
                  <v-list-item-title>Due Diligence Date</v-list-item-title>
                  <v-list-item-subtitle>{{
                    detail.due_diligence_date
                  }}</v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>

              <v-list-item>
                <v-list-item-content>
                  <v-list-item-title
                    >Due Diligence Expired Date</v-list-item-title
                  >
                  <v-list-item-subtitle>{{
                    detail.due_diligence_expired_date
                  }}</v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>

              <v-list-item>
                <v-list-item-content>
                  <v-list-item-title>Remarks</v-list-item-title>
                  <v-list-item-subtitle>{{
                    detail.remarks
                  }}</v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>

              <!-- Add more fields as needed -->
            </v-list>
          </v-col>
        </v-row>
      </v-container>

      <v-card-text>
        <v-tabs v-model="tab" color="primary" slider-color="primary">
          <v-tab> Vendor Documents </v-tab>
          <v-tab> Due Diligence History </v-tab>
          <v-tab> Update Log </v-tab>
          <v-tab-item>
            <v-simple-table>
              <template v:slot="default">
                <thead>
                  <tr>
                    <th>Document Name</th>
                    <th>Description</th>
                    <th>Date Added</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>
                      <a href="javascript:void()">last_project_doc.pdf</a>
                    </td>
                    <td>Dokumentasi dari proyek terakhir yang dilakukan</td>
                    <td>20 April 2023</td>
                  </tr>
                  <tr>
                    <td colspan="3">
                      <v-btn color="primary" text>+ Add Document</v-btn>
                    </td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
          </v-tab-item>
          <v-tab-item>
            <v-simple-table>
              <template v:slot="default">
                <thead>
                  <tr>
                    <th>Due Diligence Date</th>
                    <th>Due Diligence Expired Date</th>
                    <th>Due Diligence Remarks</th>
                    <th>Detail</th>
                    <th>Due Diligence By</th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="(dueDiligence, index) in dueDiligenceList"
                    :key="dueDiligence.date + '-' + index"
                  >
                    <td>
                      {{ dueDiligence.date }}
                    </td>
                    <td>
                      {{ dueDiligence.expiredDate }}
                    </td>
                    <td>
                      {{ dueDiligence.remarks }}
                    </td>
                    <td>
                      {{ dueDiligence.detail }}
                    </td>
                    <td>
                      {{ dueDiligence.ddBy }}
                    </td>
                  </tr>
                  <tr>
                    <td colspan="5">
                      <v-btn
                        color="primary"
                        @click="dueDiligenceDialog = true"
                        text
                        >+ Add Due Diligence</v-btn
                      >
                    </td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
          </v-tab-item>
          <v-tab-item>
            <v-simple-table>
              <template v:slot="default">
                <thead>
                  <tr>
                    <th>Updated At</th>
                    <th>Updated By</th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="(updateLog, index) in updateLogList"
                    :key="updateLog.date + '-' + index"
                  >
                    <td>
                      {{ updateLog.date }}
                    </td>
                    <td>
                      {{ updateLog.updatedBy }}
                    </td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
          </v-tab-item>
        </v-tabs>
      </v-card-text>
    </v-card>

    <v-dialog
      v-model="dueDiligenceDialog"
      scrollable
      max-width="500px"
      transition="dialog-transition"
    >
      <v-card>
        <v-card-title primary-title> Add Due Diligence </v-card-title>
        <v-card-text>
          <v-form @submit.prevent="submitForm">
            <v-row>
              <v-col cols="12" md="6">
                <v-menu
                  v-model="datePickerOpen"
                  :close-on-content-click="false"
                  offset-y
                >
                  <template v-slot:activator="{ on }">
                    <v-text-field
                      v-model="form.date"
                      label="Date"
                      prepend-icon="mdi-calendar"
                      readonly
                      v-on="on"
                      required
                    ></v-text-field>
                  </template>
                  <v-date-picker v-model="form.date" no-title></v-date-picker>
                </v-menu>
              </v-col>

              <v-col cols="12" md="6">
                <v-menu
                  v-model="expiredDatePickerOpen"
                  :close-on-content-click="false"
                  offset-y
                >
                  <template v-slot:activator="{ on }">
                    <v-text-field
                      v-model="form.expiredDate"
                      label="Expired Date"
                      prepend-icon="mdi-calendar"
                      readonly
                      v-on="on"
                      required
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="form.expiredDate"
                    no-title
                  ></v-date-picker>
                </v-menu>
              </v-col>

              <v-col cols="12">
                <v-select
                  v-model="form.remarks"
                  :items="['Passed', 'Not Passed']"
                  label="Remarks"
                  required
                ></v-select>
              </v-col>

              <v-col cols="12">
                <v-textarea
                  v-model="form.detail"
                  label="Detail"
                  required
                ></v-textarea>
              </v-col>

              <v-col cols="12">
                <v-btn color="primary" type="submit">Submit</v-btn>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      detail: {},
      tab: false,
      dueDiligenceList: [
        {
          date: "2021-05-20",
          expiredDate: "2022-05-20",
          remarks: "Passed",
          detail: "Project makan bang",
          ddBy: "Agoes",
        },
        {
          date: "2022-05-20",
          expiredDate: "2023-05-20",
          remarks: "Passed",
          detail: "Project makan bang II",
          ddBy: "Agoes",
        },
      ],
      dueDiligenceDialog: false,
      form: {
        date: "2021-05-20",
        expiredDate: "2022-05-20",
        remarks: "Passed",
        detail: "",
        ddBy: "Agoes",
      },
      updateLogList: [
        {
          date: "2021-05-20 20:00",
          updatedBy: "Agoes",
        },
        {
          date: "2022-05-20 21:00",
          updatedBy: "Agoes",
        },
      ],
      datePickerOpen: false,
      expiredDatePickerOpen: false,
    };
  },
  mounted() {
    let that = this;
    this.detail = this.$store.state.vendorList.find(function (item) {
      return item.id == that.$route.params.vendorId;
    });
  },
  methods: {
    submitForm() {
      this.dueDiligenceList.unshift(this.form);
      this.form = {
        date: "2021-05-20",
        expiredDate: "2022-05-20",
        remarks: "Passed",
        detail: "",
        ddBy: "Agoes",
      };
      this.dueDiligenceDialog = false;
    },
  },
};
</script>
