<template>
  <v-app id="inspire">
    <v-navigation-drawer app v-model="drawer">
      <v-list-item>
        <v-list-item-content>
          <v-list-item-title class="text-h6"> Shopee </v-list-item-title>
          <v-list-item-subtitle> subtext </v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>

      <v-divider></v-divider>

      <v-list dense nav>
        <v-list-item v-for="item in items" :key="item.title" link :to="item.to">
          <v-list-item-icon>
            <v-icon>{{ item.icon }}</v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title>{{ item.title }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>

    <v-app-bar app>
      <v-app-bar-nav-icon @click="drawer = !drawer"></v-app-bar-nav-icon>

      <v-toolbar-title>Vendor Management</v-toolbar-title>
    </v-app-bar>

    <v-main>
      <v-container fluid>
        <v-row dense>
          <v-col>
            <router-view></router-view>
          </v-col>
        </v-row>
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
export default {
  data: () => ({
    drawer: null,
    items: [
      {
        title: "Vendor List",
        icon: "mdi-contacts",
        to: "/vendors",
      },
      {
        title: "Master Data Entity",
        to: "/masters/entities",
        icon: "mdi-database-settings-outline",
      },
      {
        title: "Master Data Specialist",
        to: "/masters/specialists",
        icon: "mdi-database-settings-outline",
      },
      {
        title: "Master Data Commodity",
        to: "/masters/commodities",
        icon: "mdi-database-settings-outline",
      },
      {
        title: "About",
        icon: "mdi-information-outline",
        to: "/about",
      },
    ],
  }),
};
</script>
