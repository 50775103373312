<template>
  <div>
    <v-card>
      <v-card-title primary-title>
        List Vendor <v-spacer></v-spacer>
        <v-btn color="primary" @click="addNewDialog = true" outlined
          >+ Add New Vendor</v-btn
        >
      </v-card-title>
      <v-divider></v-divider>
      <v-card-text>
        <v-row>
          <v-col cols="12" md="2">
            <v-btn color="primary" @click="exportToExcel"
              >Export to Excel</v-btn
            >
          </v-col>
          <v-col cols="12" md="2">
            <v-btn color="success" @click="importFromExcel"
              >Import from Excel</v-btn
            >
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" md="2">
            <v-text-field
              v-model="search"
              label="Search by Vendor Name"
              outlined
              dense
            ></v-text-field>
          </v-col>
          <v-col cols="12" md="2">
            <v-select
              v-model="selectedEntities"
              :items="entityOptions"
              label="Entity"
              multiple
              outlined
              clearable
              dense
            ></v-select>
          </v-col>
          <v-col cols="12" md="2">
            <v-select
              v-model="selectedSpecialists"
              :items="specialistOptions"
              label="Filter by Specialist"
              outlined
              multiple
              clearable
              dense
            ></v-select>
          </v-col>
          <v-col cols="12" md="2">
            <v-select
              v-model="selectedCategories"
              :items="categoryOptions"
              label="Filter by Commodities"
              outlined
              multiple
              dense
            ></v-select>
          </v-col>
          <v-col cols="12" md="2">
            <v-menu offset-y>
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  outlined
                  dense
                  v-model="dueDiligenceDateFilter"
                  label="Due Diligence Date"
                  append-icon="mdi-calendar"
                  readonly
                  v-bind="attrs"
                  v-on="on"
                  clearable
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="dueDiligenceDateFilter"
                @input="filterItems"
                min="2000-01-01"
                max="2050-12-31"
              ></v-date-picker>
            </v-menu>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" md="2">
            <v-select
              v-model="cityBaseTownFilter"
              :items="cityBaseTownOptions"
              label="City Base/Town"
              multiple
              dense
              outlined
              clearable
            ></v-select>
          </v-col>
          <v-col cols="12" md="2">
            <v-select
              v-model="typeOfVendorFilter"
              :items="typeOfVendorOptions"
              label="Type of Vendor"
              multiple
              dense
              outlined
              clearable
            ></v-select>
          </v-col>
          <v-col cols="12" md="2">
            <v-select
              v-model="topFilter"
              :items="topOptions"
              label="Top"
              multiple
              dense
              outlined
              clearable
            ></v-select>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12">
            <h4>Status Filter</h4>
          </v-col>
          <v-col cols="12" md="2">
            <v-select
              v-model="registerStatusFilter"
              :items="registerStatusOptions"
              label="Register Status"
              multiple
              dense
              outlined
              clearable
            ></v-select>
          </v-col>
          <v-col cols="12" md="2">
            <v-select
              v-model="reviewStatusFilter"
              :items="reviewStatusOptions"
              label="Review status"
              multiple
              dense
              outlined
              clearable
            ></v-select>
          </v-col>
          <v-col cols="12" md="2">
            <v-select
              v-model="lastDDStatusFilter"
              :items="lastDDStatusOptions"
              label="Last DD Status"
              multiple
              dense
              outlined
              clearable
            ></v-select>
          </v-col>
        </v-row>
        <v-data-table
          :headers="headers"
          :items="filteredItems"
          :custom-filter="customFilter"
        >
          <template v-slot:[`item.vendor_name`]="{ item }">
            <a
              :href="`vendors/detail/${item.id}`"
              target="_blank"
              class="vendor-link"
            >
              {{ item["vendor_name"] }}
            </a>
          </template>
          <template v-slot:[`item.cat_level_3`]="{ item }">
            {{ item.cat_level_3.join(", ") }}
          </template>
          <template v-slot:[`item.status`]="{ item }">
            <v-chip v-if="item.status == 'registered'" color="success" small
              >Registered</v-chip
            >
            <v-chip v-else small>{{ item.status }}</v-chip>
          </template>
          <template v-slot:[`item.remarks`]="{ item }">
            <v-chip v-if="item.remarks == 'Passed'" color="success" small
              >Passed</v-chip
            >
            <v-chip v-else small>{{ item.remarks }}</v-chip>
          </template>
        </v-data-table>
      </v-card-text>
    </v-card>

    <v-dialog
      v-model="addNewDialog"
      scrollable
      :overlay="false"
      max-width="800px"
      transition="dialog-transition"
    >
      <v-card>
        <v-card-title primary-title> Add New Vendor </v-card-title>
        <v-divider></v-divider>
        <v-card-text>
          <v-row>
            <v-col cols="6">
              <v-text-field
                v-model="formData.vendor_name"
                label="Vendor Name"
              ></v-text-field>
            </v-col>
            <v-col cols="6">
              <v-select
                v-model="formData.company_type"
                :items="companyTypeOptions"
                label="Company Type"
              ></v-select>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="6">
              <v-text-field v-model="formData.pic" label="PIC"></v-text-field>
            </v-col>
            <v-col cols="6">
              <v-text-field
                v-model="formData.email"
                label="Email"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="6">
              <v-text-field
                v-model="formData.contact_number"
                label="Contact Number"
              ></v-text-field>
            </v-col>
            <v-col cols="6">
              <v-text-field
                v-model="formData.project_name"
                label="Project Name"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="6">
              <v-select
                v-model="formData.entity"
                :items="entityOptions"
                label="Entity"
                multiple
              ></v-select>
            </v-col>
            <v-col cols="6">
              <v-select
                v-model="formData.cat_level_3"
                :items="categoryOptions"
                label="Commodity"
                multiple
              ></v-select>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="6">
              <v-select
                v-model="formData.specialist"
                :items="specialistOptions"
                label="Specialist"
              ></v-select>
            </v-col>
            <v-col cols="6">
              <v-select
                v-model="formData.city_basetown"
                :items="cityBaseTownOptions"
                label="City/BaseTown"
              ></v-select>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="6">
              <v-select
                v-model="formData.type_of_vendor"
                :items="typeOfVendorOptions"
                label="Type of Vendor"
                dense
              ></v-select>
            </v-col>
            <v-col cols="6">
              <v-select
                v-model="formData.top"
                :items="topOptions"
                label="TOP"
                dense
              ></v-select>
            </v-col>
          </v-row>
          <v-row> </v-row>
          <v-row>
            <v-col cols="12">
              <v-textarea v-model="formData.detail" label="Detail"></v-textarea>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12"> </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="error" text @click="addNewDialog = false">Cancel</v-btn>
          <v-btn color="primary" @click="submitForm">Submit</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import Vue from "vue";
import { utils, writeFile } from "xlsx";

export default {
  data() {
    return {
      headers: [
        { text: "Entity", value: "entity" },
        {
          text: "Status",
          value: "status",
        },
        { text: "Review Status", value: "review" },
        { text: "Vendor Name", value: "vendor_name" },
        { text: "Company Type", value: "company_type" },
        { text: "PIC", value: "pic" },
        { text: "Email", value: "email" },
        { text: "Contact Number", value: "contact_number" },
        { text: "Address", value: "address" },
        { text: "Commodity", value: "cat_level_3" },
        { text: "Specialist", value: "specialist" },
        { text: "City/BaseTown", value: "city_basetown" },
        { text: "Type of Vendor", value: "type_of_vendor" },
        { text: "TOP", value: "top" },
        { text: "Project Name", value: "project_name" },
        { text: "Last Due Diligence", value: "due_diligence_date" },
        {
          text: "Due Diligence Expired Date",
          value: "due_diligence_expired_date",
        },
        { text: "Last Remarks", value: "remarks" },
      ],
      items: this.$store.state.vendorList,
      search: "",
      selectedSpecialists: [],
      selectedCategories: [],
      selectedEntities: [],
      dueDiligenceDateFilter: null,
      cityBaseTownFilter: [],
      typeOfVendorFilter: [],
      topFilter: [],
      registerStatusFilter: [],
      reviewStatusFilter: [],
      lastDDStatusFilter: [],
      loading: false,
      addNewDialog: false,
      formData: {
        id: 11,
        entity: [],
        status: "",
        review: "",
        vendor_name: "",
        company_type: "",
        pic: "",
        email: "",
        contact_number: null,
        cat_level_3: [],
        specialist: "",
        city_basetown: "",
        type_of_vendor: "",
        top: "",
        project_name: "",
        due_diligence_date: "",
        due_diligence_expired_date: "",
        remarks: "",
        detail: "",
      },
      companyTypeOptions: [
        { text: "PT", value: "PT" },
        { text: "CV", value: "CV" },
      ],
    };
  },
  methods: {
    customFilter(item) {
      if (this.selectedCategories.length > 0) {
        if (
          !item.cat_level_3.some((catLevel) =>
            this.selectedCategories.includes(catLevel)
          )
        ) {
          return false;
        }
      }

      if (
        this.search &&
        !item.vendor_name.toLowerCase().includes(this.search.toLowerCase())
      ) {
        return false;
      }
      // Filter by entity
      if (this.selectedEntities.length > 0) {
        if (
          !item.entity.some((entity) => this.selectedEntities.includes(entity))
        ) {
          return false;
        }
      }

      if (this.selectedSpecialists.length > 0) {
        if (!this.selectedSpecialists.includes(item.specialist)) {
          return false;
        }
      }

      if (this.dueDiligenceDateFilter) {
        const filterDate = new Date(this.dueDiligenceDateFilter);
        const itemDate = new Date(item.due_diligence_expired_date);
        if (filterDate > itemDate) {
          return false;
        }
      }

      if (this.cityBaseTownFilter.length > 0) {
        if (!this.cityBaseTownFilter.includes(item.city_basetown)) {
          return false;
        }
      }

      if (this.typeOfVendorFilter.length > 0) {
        if (!this.typeOfVendorFilter.includes(item.type_of_vendor)) {
          return false;
        }
      }

      if (this.topFilter.length > 0) {
        if (!this.topFilter.includes(item.top)) {
          return false;
        }
      }

      if (this.registerStatusFilter.length > 0) {
        if (!this.registerStatusFilter.includes(item.status)) {
          return false;
        }
      }

      if (this.reviewStatusFilter.length > 0) {
        if (!this.reviewStatusFilter.includes(item.review)) {
          return false;
        }
      }

      if (this.lastDDStatusFilter.length > 0) {
        if (!this.lastDDStatusFilter.includes(item.remarks)) {
          return false;
        }
      }
      // All filters passed
      return true;
    },
    filterItems() {
      this.$nextTick(() => {
        // Trigger the computed property to update the filtered items
        this.filteredItems;
      });
    },
    exportToExcel() {
      this.loading = true;

      const workbook = utils.book_new();
      const worksheet = utils.json_to_sheet(this.items);

      utils.book_append_sheet(workbook, worksheet, "Sheet1");

      writeFile(workbook, "data.xlsx");

      this.loading = false;
    },
    importFromExcel() {},
    submitForm() {
      this.formData.status =
        this.formData.entity.length > 0 ? "registered" : "not-registered";
      this.items.unshift(this.formData);
      Vue.set(this.$store.state, "vendorList", this.items);
      this.addNewDialog = false;
    },
  },
  computed: {
    filteredItems() {
      return this.items.filter(this.customFilter);
    },

    specialistOptions() {
      // Extract unique specialist values from the items
      const specialists = [
        ...new Set(this.items.map((item) => item.specialist)),
      ];
      return specialists.sort(); // Sort the specialist options alphabetically
    },
    categoryOptions() {
      // Extract unique categories values from the items
      const catLevels = new Set();
      this.items.forEach((item) => {
        item.cat_level_3.forEach((catLevel) => {
          catLevels.add(catLevel);
        });
      });
      return Array.from(catLevels);
    },
    entityOptions() {
      const entities = new Set();
      this.items.forEach((item) => {
        item.entity.forEach((entity) => {
          entities.add(entity);
        });
      });
      return Array.from(entities);
    },
    topOptions() {
      const tops = [...new Set(this.items.map((item) => item.top))];
      return tops.sort();
    },
    typeOfVendorOptions() {
      const typeOfVendors = [
        ...new Set(this.items.map((item) => item.type_of_vendor)),
      ];
      return typeOfVendors.sort();
    },
    cityBaseTownOptions() {
      const cityBaseTowns = [
        ...new Set(this.items.map((item) => item.city_basetown)),
      ];
      return cityBaseTowns.sort();
    },

    registerStatusOptions() {
      const registerStatuses = [
        ...new Set(this.items.map((item) => item.status)),
      ];
      return registerStatuses.sort();
    },

    reviewStatusOptions() {
      const reviewStatuses = [
        ...new Set(this.items.map((item) => item.review)),
      ];
      return reviewStatuses.sort();
    },

    lastDDStatusOptions() {
      const lastDDStatuses = [
        ...new Set(this.items.map((item) => item.remarks)),
      ];
      return lastDDStatuses.sort();
    },
  },
};
</script>
